export { default } from "../components/page/login-and-reset-request";
import { getI18NLogin } from "src/services/i18n/getI18NLogin";

export const getStaticProps = async ({ locale: l }) => {
  const locale = l || "en";

  const [login] = await Promise.all([getI18NLogin({ locale })]);

  return {
    revalidate: isNaN(parseInt(`${process.env.NEXT_PUBLIC_I18N_REVALIDATE}`))
      ? 10
      : parseInt(`${process.env.NEXT_PUBLIC_I18N_REVALIDATE}`),
    props: {
      i18n: {
        login,
      },
    },
  };
};
